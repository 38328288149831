import React, { useCallback, useContext, useState, useEffect } from "react";
import { Button, Spinner } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import "../App.css";
import { orgContext, borrowerContext } from "../helpers/context";
import { acceptLoanTerms } from "../helpers/api";
import { ILoanTerms } from "../types";
import { formatNumberAmount } from "../helpers/utilFunctions";
import _ from "lodash";

interface LoanConfirmationProps {
  history: any;
}

const LoanConfirmation = ({ history }: LoanConfirmationProps): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [loanTerms, setLoanTerms] = useState<ILoanTerms[]>([]);
  const { organizations } = useContext(orgContext);
  const { borrowers } = useContext(borrowerContext);
  const [user] = borrowers;
  const [client] = organizations;
  const { website } = client;
  const location = useLocation<{
    loanAmount: string;
    eligibilityId: string;
    tenure: number;
  }>();
  const clientname = location.pathname.split("/")[1];

  const formattedLoanAmount = parseFloat(
    location.state.loanAmount.replace(/,/g, "")
  );
  const [loanTerm] = loanTerms;
  const hasLinkToSite = _.isEmpty(website) ? false : true;

  // const calculateRepayment = (
  //   totalRepayment: number,
  //   totalFee: number,
  //   tenure: number
  // ) => {
  //   let repayment = (totalRepayment - totalFee) / tenure;

  //   return formatNumberAmount(repayment);
  // };

  const poulateLoanTerms = useCallback(async () => {
    if (loanTerms.length === 0) {
      try {
        const terms = await acceptLoanTerms(
          formattedLoanAmount,
          client["x-tag"],
          user._id,
          location.state.eligibilityId,
          user.token
        );
        setLoanTerms([...loanTerms, terms.data.data]);
      } catch (error: any) {
        history.push(`/${clientname}/error`, {
          error: error?.response?.data?.message,
        });
      }
    }
  }, [
    client,
    clientname,
    formattedLoanAmount,
    history,
    loanTerms,
    location.state.eligibilityId,
    user,
  ]);

  const handleClick = async () => {
    history.push(`/${clientname}/confirm-identity`);
  };
  const handleCancel = () => {
    history.push(`/${clientname}/error`, {
      error: "You did not accept the loan terms",
    });
  };

  useEffect(() => {
    setLoading(true);
    poulateLoanTerms();
    setLoading(false);
  }, [poulateLoanTerms, loanTerms]);

  return (
    <div className="body-main m-auto text-center">
      <div className="steps-wrapper">
        <div className="step active">
          <span className="bold-text text-smaller">1</span>
        </div>
        <span className="step-divider active my-auto"></span>
        <div className="step active">
          <span className="bold-text text-smaller">2</span>
        </div>
        <span className="step-divider active my-auto"></span>
        <div className="step active">
          <span className="bold-text text-smaller">3</span>
        </div>
        <span className="step-divider my-auto"></span>
        <div className="step">
          <span className="bold-text text-smaller">4</span>
        </div>
      </div>
      <h5 className="bold-text text-small mb-4">Confirm Loan Application</h5>
      <div className="loan-confirmation-box">
        {loading || loanTerms.length > 0 ? (
          <>
            <div className="loan-confirmation-row">
              <p className="text-smaller">Principal</p>
              <p className="text-smaller">{`\u20A6 ${formatNumberAmount(
                loanTerm.amount
              )}`}</p>
            </div>
            {/* <div className="loan-confirmation-row">
              <p className="text-smaller">Amount to Disburse</p>
              <p className="text-smaller">{`\u20A6 ${formatNumberAmount(
                loanTerm.Disbursable
              )}`}</p>
            </div> */}
            <div className="loan-confirmation-row">
              <p className="text-smaller">Tenure</p>
              <p className="text-smaller">
                {loanTerm.tenure} {loanTerm.tenure > 1 ? "Months" : "Month"}
              </p>
            </div>
            <div className="loan-confirmation-row">
              <p className="text-smaller text-left">Repayment Date</p>
              <p className="text-smaller text-end">
                Next Payment From Employer
              </p>
            </div>
            <div className="loan-confirmation-row">
              <p className="text-smaller">Interest Rate</p>
              <p className="text-smaller">{loanTerm.interestRate}%</p>
            </div>
            <div className="loan-confirmation-row">
              <p className="text-smaller">Repayment</p>
              <p className="text-smaller">{`\u20A6 ${formatNumberAmount(
                loanTerm.repaymentAmount
              )} ${loanTerm.tenure > 1 ? "Monthly" : ""}`}</p>
            </div>
            <div className="loan-confirmation-row">
              <p className="text-smaller">Upfront Fee</p>
              <p className="text-smaller">{`\u20A6 ${formatNumberAmount(
                loanTerm.fee
              )}`}</p>
            </div>
            <div className="loan-confirmation-row">
              <p className="text-smaller">Installment Fee</p>
              <p className="text-smaller">{`\u20A6 ${formatNumberAmount(
                loanTerm.instalmentFee
              )}`}</p>
            </div>
          </>
        ) : (
          <Spinner size="md" color="primaryBlue" className="my-5" />
        )}
      </div>

      <div>
        <Button variant="primary" className="mt-3" onClick={handleClick}>
          Accept Terms
        </Button>
      </div>
      <div>
        <Button variant="secondary" className="mt-3" onClick={handleCancel}>
          Decline
        </Button>
      </div>
      {hasLinkToSite && (
        <p className="text-smallest accept-terms">
          By clicking "Accept Terms" you agree to the{" "}
          <a href={website} target="_blank" rel="noreferrer">
            terms and conditions
          </a>
        </p>
      )}
    </div>
  );
};

export default LoanConfirmation;
