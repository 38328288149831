import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import { Button } from "@chakra-ui/react";
import { Formik, Form } from "formik";
import * as yup from "yup";
import "../App.css";
import InputField from "../components/common/InputField";
import {
  confirmIdentityContext,
  orgContext,
  borrowerContext,
} from "../helpers/context";
import { verifyOTP } from "../helpers/api";
import { useState } from "react";

interface OTPInputProps {
  history: any;
}

interface Values {
  otp: string;
}

const OTPInput = ({ history }: OTPInputProps): JSX.Element => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const { identities } = useContext(confirmIdentityContext);
  const { organizations } = useContext(orgContext);
  const { borrowers } = useContext(borrowerContext);
  const [user] = borrowers;
  const [client] = organizations;
  const [identity] = identities;
  const location = useLocation();
  const clientname = location.pathname.split("/")[1];

  const validateForm = async (token: string, otp: string) => {
    setSubmitting(true);
    try {
      await verifyOTP(
        client["x-tag"],
        token,
        otp,
        identity.firstName,
        identity.lastName,
        identity.email
      );
      setSubmitting(false);
      history.push(`/${clientname}/loandisbursed`);
    } catch (error: any) {
      history.push(`/${clientname}/error`, {
        error: error?.response?.data?.message,
      });
    }
  };
  const digitRegExp = /^[0-9]*$/;

  return (
    <div className="body-main m-auto text-center">
      <h5 className="bold-text text-small mb-4">Provide 4-digit OTP</h5>
      <Formik
        initialValues={{
          otp: "",
        }}
        onSubmit={(values: Values) => {
          validateForm(user.token, values.otp);
        }}
        validationSchema={yup.object().shape({
          otp: yup
            .string()
            .required("Please enter OTP sent to your mobile number")
            .matches(digitRegExp, "OTP is not valid")
            .max(4, "OTP must not be more than 4 digits"),
        })}
      >
        {({ values, handleChange, handleBlur, errors }) => (
          <Form>
            <InputField
              inputType="tel"
              name="otp"
              placeholder="Enter OTP"
              value={values.otp}
              onBlur={() => handleBlur}
              onChange={handleChange}
              error={errors.otp}
            />
            <Button
              type="submit"
              variant="primary"
              className="mt-4"
              isLoading={submitting}
            >
              Verify OTP
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default OTPInput;
