import React, { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import { Button } from "@chakra-ui/react";
import { Formik, Form } from "formik";
import * as yup from "yup";
import "../App.css";
import InputField from "../components/common/InputField";
import {
  borrowerContext,
  orgContext,
  confirmIdentityContext,
  originationContext,
} from "../helpers/context";
import { sendOTP, disburseLoan } from "../helpers/api";
import { IIdentity } from "../types";

interface ConfirmIdentityProps {
  history: any;
}

interface Values {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}

const ConfirmIdentity = ({ history }: ConfirmIdentityProps): JSX.Element => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [identity, setIdentity] = useState<IIdentity[]>([]);
  const { borrowers } = useContext(borrowerContext);
  const { organizations } = useContext(orgContext);
  const identityContext = useContext(confirmIdentityContext);
  const {
    origin: { originLender },
  } = useContext(originationContext);
  const [client] = organizations;
  const [user] = borrowers;
  const location = useLocation();
  const clientname = location.pathname.split("/")[1];

  const callSendOTP = async (token: string) => {
    setSubmitting(true);
    try {
      await sendOTP(client["x-tag"], token);
      setSubmitting(false);
      history.push(`/${clientname}/otp`);
    } catch (error: any) {
      history.push(`/${clientname}/error`, {
        error: error?.response?.data?.message,
      });
    }
  };

  const callDisburseLoan = async (
    firstName: string,
    lastName: string,
    email: string
  ) => {
    setSubmitting(true);
    try {
      await disburseLoan(
        client["x-tag"],
        user?._id,
        user?.token,
        firstName,
        lastName,
        email
      );
      setSubmitting(false);
      history.push(`/${clientname}/loandisbursed`);
    } catch (error: any) {
      setSubmitting(false);
      history.push(`/${clientname}/error`, {
        error: error?.response?.data?.message,
      });
    }
  };
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  return (
    <div className="body-main m-auto text-center">
      <div className="steps-wrapper">
        <div className="step active">
          <span className="bold-text text-smaller">1</span>
        </div>
        <span className="step-divider active my-auto"></span>
        <div className="step active">
          <span className="bold-text text-smaller">2</span>
        </div>
        <span className="step-divider active my-auto"></span>
        <div className="step active">
          <span className="bold-text text-smaller">3</span>
        </div>
        <span className="step-divider active my-auto"></span>
        <div className="step active">
          <span className="bold-text text-smaller">4</span>
        </div>
      </div>
      <h5 className="bold-text text-small mb-4">Personal Details</h5>
      <p className="mt-4 text-left text-smaller bold-text">
        Confirm your Identity
      </p>
      <Formik
        initialValues={{
          firstName: user?.firstName?.length === 0 ? "" : user.firstName,
          lastName: user?.lastName?.length === 0 ? "" : user.lastName,
          phoneNumber: user.phoneNumber,
          email: user?.email?.length === 0 ? "" : user.email,
        }}
        onSubmit={(values: Values) => {
          if (identity.length === 0) {
            setIdentity([...identity, values]);
            identityContext.setIdentities([...identity, values]);
          }
          if (originLender) {
            callDisburseLoan(values.firstName, values.lastName, values.email);
          } else {
            callSendOTP(user.token);
          }
        }}
        validationSchema={yup.object().shape({
          firstName: yup.string().required("Please provide your first name"),
          lastName: yup.string().required("Please provide your last name"),
          phoneNumber: yup
            .string()
            .required("Please provide your phone number")
            .matches(phoneRegExp, "Phone number is not valid")
            .min(9, "number too short")
            .max(15, "number too long"),
          email: yup
            .string()
            .email("email not valid")
            .required("Please provide your email address"),
        })}
      >
        {({ values, handleChange, handleBlur, errors }) => (
          <Form>
            <InputField
              inputType="text"
              name="firstName"
              placeholder="Enter First Name"
              value={values.firstName}
              onBlur={() => handleBlur}
              onChange={handleChange}
              error={errors.firstName}
              isDisabled={user?.firstName?.length > 0 ? true : false}
              hasLabel
              label="First Name"
            />
            <InputField
              inputType="text"
              name="lastName"
              placeholder="Enter Last Name"
              value={values.lastName}
              onBlur={() => handleBlur}
              onChange={handleChange}
              error={errors.lastName}
              isDisabled={user?.lastName?.length > 0 ? true : false}
              hasLabel
              label="Last Name"
            />
            <InputField
              inputType="tel"
              name="phoneNumber"
              placeholder="Enter Phone Number"
              value={values.phoneNumber}
              onBlur={() => handleBlur}
              onChange={handleChange}
              error={errors.phoneNumber}
              isDisabled
              hasLabel
              label="Phone Number"
            />
            <InputField
              inputType="email"
              name="email"
              placeholder="Enter Email Address"
              value={values.email}
              onBlur={() => handleBlur}
              onChange={handleChange}
              error={errors.email}
              isDisabled={user?.email?.length > 0 ? true : false}
              hasLabel
              label="Email Address"
            />
            <Button
              type="submit"
              variant="primary"
              className="mt-4"
              style={{ maxWidth: "100%" }}
              isLoading={isSubmitting}
            >
              {originLender ? "Disburse Loan" : "Send OTP"}
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ConfirmIdentity;
