import React from "react";
import Icon from "@material-ui/core/Icon";
import "./../styles/InputFieldStyles.css";
interface InputFieldProps {
  name: string;
  onChange: (e: any) => void;
  onBlur: () => void;
  placeholder: string;
  value?: string;
  error: any;
  inputType: string;
  hasLabel?: boolean;
  label?: string;
  isDisabled?: boolean;
  defaultValue?: string;
}

const InputField: React.FC<InputFieldProps> = (props) => {
  const {
    name,
    placeholder,
    value,
    onChange,
    onBlur,
    error,
    inputType,
    hasLabel,
    label,
    isDisabled,
    defaultValue,
  } = props;
  let wrapperClass = "form-group mt-3";
  if (error && error.length > 0) {
    wrapperClass += " has-error";
  }

  return (
    <div className={wrapperClass}>
      {hasLabel && (
        <div className="label-div mb-1">
          <p className="label">{label}</p>
        </div>
      )}
      <input
        type={inputType}
        name={name}
        className="form-control my-2 custom-input"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={isDisabled}
        defaultValue={defaultValue}
      />
      {error && (
        <div className="error-div">
          <Icon fontSize={"small"} color={"error"}>
            error
          </Icon>
          <p className="text-smaller ml-2">{error}</p>
        </div>
      )}
    </div>
  );
};

export default InputField;
