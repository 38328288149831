import Apply from "../views/Apply";
import Consent from "../views/Consent";
import Eligibility from "../views/Eligibility";
import LoanAmount from "../views/LoanAmount";
import LoanConfirmation from "../views/LoanConfirmation";
import ConfirmIdentity from "../views/ConfirmIdentity";
import LoanFailed from "../views/LoanFailed";
import LoanSentBox from "../views/LoanSent";
import OTPInput from "../views/OTPInput";
import { IRoutes } from "../types";

export const routes: IRoutes[] = [
  {
    path: "/apply",
    name: "Apply",
    component: Apply,
  },
  {
    path: "/consent",
    name: "Consent",
    component: Consent,
  },
  {
    path: "/eligibility",
    name: "Eligibility",
    component: Eligibility,
  },
  {
    path: "/selectamount",
    name: "LoanAmount",
    component: LoanAmount,
  },
  {
    path: "/confirm-identity",
    name: "ConfirmIdentity",
    component: ConfirmIdentity,
  },
  {
    path: "/loanterms",
    name: "LoanConfirmation",
    component: LoanConfirmation,
  },
  {
    path: "/loandisbursed",
    name: "LoanSent",
    component: LoanSentBox,
  },
  {
    path: "/otp",
    name: "OTPInput",
    component: OTPInput,
  },
  {
    path: "/error",
    name: "LoanFailed",
    component: LoanFailed,
  },
];
