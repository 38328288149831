import React, { useState, useContext, useCallback, useEffect } from "react";
import logo from "./assets/veend-logo.png";
import "./App.css";
import { Route, Switch, useLocation } from "react-router-dom";
import { routes } from "./routes";
import { IOrg, IRoutes } from "./types";
import {
  orgContext,
  borrowerContext,
  originationContext,
} from "./helpers/context";
import { getLender, registerCustomer } from "./helpers/api";
import { Spinner } from "@chakra-ui/react";
import { capitalizeString } from "./helpers/utilFunctions";

interface InitializeProps {
  history: any;
}

const Initialize = ({ history }: InitializeProps): JSX.Element => {
  const [organizations, setOrganizations] = useState<IOrg[]>([]);
  const organizationContext = useContext(orgContext);
  const userContext = useContext(borrowerContext);
  const originContext = useContext(originationContext);
  const { origin, setOrigin } = originContext;
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const clientname = location.pathname.split("/")[1];

  const getRoutes = (routes: IRoutes[]) => {
    return routes.map((props, index) => {
      return (
        <Route
          path={`/${clientname}${props.path}`}
          component={props.component}
          key={index}
        />
      );
    });
  };

  const setPageTitle = (lenderName: string) => {
    document.title = `${capitalizeString(lenderName)} Loan Application`;
  };

  const setReferrer = (client: any) => {
    const referrer = client?.isOriginate === true ? "VeendHQ" : client?.client;
    return referrer;
  };

  const termsUrl = process.env.REACT_APP_CONSUMER_TERMS_URL;

  const handleRedirect = useCallback(async () => {
    let reqName = location.pathname.split("/")[2].split("?")[0];
    let xTag = location.search.split("?x-tag=")[1].split("&")[0];
    let id = location.search.split("id=")[1];
    try {
      setPageTitle(reqName);
      const lender = await getLender(reqName);
      setOrganizations([...organizations, lender?.data?.data]);
      organizationContext.setOrganizations([
        ...organizations,
        lender?.data?.data,
      ]);
      const client = lender?.data?.data;
      const referrer = setReferrer(client);
      const user = await registerCustomer(id, xTag, referrer);
      userContext.setBorrowers([user?.data?.data]);
      setLoading(false);
      history.push(`/${reqName}/consent`);
    } catch (error: any) {
      history.push(`/${reqName}/error`, {
        error: error?.response?.data?.message,
      });
      setLoading(false);
    }
  }, [history, location, organizationContext, organizations, userContext]);

  const handleThirdPartyRedirect = useCallback(async () => {
    let thirdPartyName = location.pathname.split("/")[2].split("?")[0];
    let xTag = location.search.split("?x-tag=")[1].split("&")[0];
    let id = location.search.split("id=")[1].split("&")[0];
    try {
      setPageTitle(thirdPartyName);
      const lender = await getLender(thirdPartyName);
      setOrganizations([...organizations, lender?.data?.data]);
      organizationContext.setOrganizations([
        ...organizations,
        lender?.data?.data,
      ]);

      const client = lender?.data?.data;
      const referrer = setReferrer(client);
      const user = await registerCustomer(id, xTag, referrer);
      userContext.setBorrowers([user?.data?.data]);
      setOrigin({ ...origin, originThirdParty: true });
      setLoading(false);
      history.push(`/${thirdPartyName}/eligibility`);
    } catch (error: any) {
      history.push(`/${thirdPartyName}/error`, {
        error: error?.response?.data?.message,
      });
      setLoading(false);
    }
  }, [
    location.pathname,
    location.search,
    organizations,
    organizationContext,
    userContext,
    setOrigin,
    origin,
    history,
  ]);

  const handleCreateLoan = useCallback(async () => {
    let lenderName = location.pathname.split("/")[2].split("?")[0];
    try {
      setPageTitle(lenderName);
      const lender = await getLender(lenderName);
      setOrganizations([...organizations, lender?.data?.data]);
      organizationContext.setOrganizations([
        ...organizations,
        lender?.data?.data,
      ]);
      setOrigin({ ...origin, originLender: true });
      setLoading(false);
      history.push(`/${lenderName}/apply`);
    } catch (error: any) {
      setLoading(false);
      history.push(`/${lenderName}/error`, {
        error: error?.response?.data?.message,
      });
    }
  }, [
    location.pathname,
    organizations,
    organizationContext,
    history,
    setOrigin,
    origin,
  ]);

  const handleV1 = useCallback(async () => {
    let lenderName = location.pathname.split("/")[2].split("?")[0];
    try {
      setPageTitle(lenderName);
      const lender = await getLender(lenderName);
      setOrganizations([...organizations, lender?.data?.data]);
      organizationContext.setOrganizations([
        ...organizations,
        lender?.data?.data,
      ]);
      setOrigin({ ...origin, originManualAmount: true });
      setLoading(false);
      history.push(`/${lenderName}/apply`);
    } catch (error: any) {
      setLoading(false);
      history.push(`/${lenderName}/error`, {
        error: error?.response?.data?.message,
      });
    }
  }, [
    location.pathname,
    organizations,
    organizationContext,
    history,
    setOrigin,
    origin,
  ]);

  useEffect(() => {
    (async () => {
      if (organizations.length === 0) {
        setLoading(true);
        if (clientname === "redirect") {
          handleRedirect();
        } else if (clientname === "thirdParty") {
          handleThirdPartyRedirect();
        } else if (clientname === "create-loan") {
          handleCreateLoan();
        } else if (clientname === "v1") {
          handleV1();
        } else {
          if (clientname) {
            try {
              setPageTitle(clientname);
              const lender = await getLender(clientname);
              setOrganizations([...organizations, lender?.data?.data]);
              organizationContext.setOrganizations([
                ...organizations,
                lender?.data?.data,
              ]);
              setLoading(false);
              history.push(`/${clientname}/apply`);
            } catch (error: any) {
              setLoading(false);
              history.push(`/${clientname}/error`, {
                error: error?.response?.data?.message,
              });
            }
          }
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="modal">
      <div className="text-center wrapper">
        <div className="modal-box">
          <div className="header-top">
            <div className="logo-img mt-3 mx-auto">
              {organizations.length > 0 || !loading ? (
                <img
                  src={organizations.length ? organizations[0].logo : logo}
                  alt="lender-logo"
                />
              ) : (
                <>
                  <Spinner size="md" color="primaryBlue" className="my-5" />
                </>
              )}
            </div>
          </div>
          {loading ? (
            <Spinner size="xl" color="primaryBlue" className="my-5" />
          ) : null}
          {<Switch>{getRoutes(routes)}</Switch>}
        </div>

        <div className="powered-by">
          <p className="text-small">
            Powered by
            <a
              href="https://veendhq.com"
              target="_blank"
              rel="noreferrer"
              className="brand-text ml-1"
            >
              VEENDHQ
            </a>
          </p>
        </div>
        <div className="powered-by">
          <p className="text-smaller">
            <a
              href={termsUrl}
              target="_blank"
              rel="noreferrer"
              className="link-text"
            >
              {`Terms & Conditions`}
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Initialize;
