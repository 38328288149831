import React from "react";
import { Button } from "@chakra-ui/react";
import { Formik, Form } from "formik";
import * as yup from "yup";
import "../App.css";
import InputField from "../components/common/InputField";

interface AmountInputFormProps {
  handleProceed: (amount: string) => void;
  maxAmount: number;
  minAmount: number;
}

interface Values {
  amount: string;
}

const AmountInputForm = ({
  handleProceed,
  maxAmount,
  minAmount,
}: AmountInputFormProps): JSX.Element => {
  return (
    <>
      <Formik
        initialValues={{
          amount: "",
        }}
        onSubmit={(values: Values) => {
          handleProceed(values.amount);
        }}
        validationSchema={yup.object().shape({
          amount: yup
            .number()
            .max(maxAmount)
            .min(minAmount)
            .required("Please enter amount you wish to borrow"),
        })}
      >
        {({ values, handleChange, handleBlur, errors }) => (
          <Form>
            <InputField
              inputType="text"
              name="amount"
              placeholder="Enter loan amount"
              value={values.amount}
              onBlur={() => handleBlur}
              onChange={handleChange}
              error={errors.amount}
            />
            <Button
              type="submit"
              variant="primary"
              className="mt-3"
              style={{ maxWidth: "100%" }}
            >
              Proceed
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AmountInputForm;
