export async function handleResponse(response: any) {
  if (response.ok) return response.data.data;
  if (response.status === 400) {
    // So, a server-side validation error occurred.
    // Server side validation returns a string error message, so parse as text instead of json.
    const error = await response.text();
    throw new Error(error);
  }
  return response;
}

// call an error logging service in prod.
export async function handleError(error: any) {
  throw error;
}
