import React, { useContext } from "react";
import { Button } from "@chakra-ui/react";
import "../App.css";
import { useLocation } from "react-router-dom";
import { capitalizeString } from "../helpers/utilFunctions";
import { orgContext } from "../helpers/context";

interface IConsentProps {
  history: any;
}

const ConsentBox = ({ history }: IConsentProps): JSX.Element => {
  const location = useLocation();
  const clientname = location.pathname.split("/")[1];
  const { organizations } = useContext(orgContext);
  const [org] = organizations;

  const handleClick = () => {
    history.push(`/${clientname}/eligibility`);
  };
  const handleCancel = () => {
    history.push(`/${clientname}/error`, {
      error: `You did not allow ${capitalizeString(
        org?.client
      )} to obtain your data`,
    });
  };
  return (
    <div className="body-main text-center m-auto">
      <h5 className="font-weight-bolder">Note</h5>
      <p className="text-small mt-3">
        I hereby authorize{" "}
        <span className="font-weight-bold">
          {capitalizeString(org?.client)}
        </span>{" "}
        to obtain my personal data from 3rd parties, deduct loan settlements
        from my salary and debit bank accounts linked to my identity for
        repayments. I acknowledge that all information provided is accurate and
        understand that once approved, this loan application is irreversible.
      </p>
      <div>
        <Button variant="primary" className="mt-3" onClick={handleClick}>
          I Consent
        </Button>
      </div>
      <div>
        <Button variant="secondary" className="mt-3" onClick={handleCancel}>
          Decline
        </Button>
      </div>
    </div>
  );
};

export default ConsentBox;
