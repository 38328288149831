import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import { Button } from "@chakra-ui/react";
import Icon from "@material-ui/core/Icon";
import "../App.css";
import {
  borrowerContext,
  orgContext,
  originationContext,
} from "./../helpers/context";

interface LoanSentProps {
  history: any;
}

const LoanSentBox = ({ history }: LoanSentProps): JSX.Element => {
  const location = useLocation();
  const clientname = location.pathname.split("/")[1];
  const { borrowers } = useContext(borrowerContext);
  const { organizations } = useContext(orgContext);
  const { origin } = useContext(originationContext);
  const { originLender, originManualAmount, originThirdParty } = origin;

  const handleClick = () => {
    if (originLender) {
      window.location.href = `/create-loan/${clientname}`;
    } else if (originThirdParty) {
      window.location.href = `/thirdParty/${clientname}?x-tag=${organizations?.[0]?.["x-tag"]}&id=${borrowers?.[0]?.phoneNumber}`;
    } else if (originManualAmount) {
      window.location.href = `/v1/${clientname}`;
    } else {
      window.location.href = `/${clientname}`;
    }
  };

  return (
    <div className="body-main text-center m-auto">
      <Icon className="check-circle" fontSize="large">
        check_circle
      </Icon>
      <p className="text-small mt-3">
        Loan application has been sent for processing, you should receive a
        credit notification from your bank shortly
      </p>
      <Button variant="primary" className="mt-4" onClick={handleClick}>
        OK
      </Button>
    </div>
  );
};

export default LoanSentBox;
