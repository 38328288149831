import { Button } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import * as yup from "yup";
import "../App.css";
import loanIcon from "../assets/loan-icon.png";
import InputField from "../components/common/InputField";
import { registerCustomer } from "../helpers/api";
import {
  borrowerContext,
  orgContext,
  originationContext,
} from "../helpers/context";
import { IBorrower } from "../types";

export interface ApplyProps {
  history: any;
}

interface Values {
  phoneNumber: string;
  accountNumber: string;
  bankCode: string;
}

const Apply = ({ history }: ApplyProps): JSX.Element => {
  const [submitting, setSubmitting] = useState(false);
  const [clientData, setClientData] = useState<any>(null);
  const [userData, setUserData] = useState<IBorrower[]>([]);
  const { organizations } = useContext(orgContext);
  const userContext = useContext(borrowerContext);
  const {
    origin: { originLender },
  } = useContext(originationContext);
  const [client] = organizations;
  const location = useLocation();
  const clientname = location.pathname.split("/")[1];
  // eslint-disable-next-line no-useless-escape
  const phoneRegExp = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;

  const setReferrer = (client: any) => {
    const referrer = client?.isOriginate === true ? "VeendHQ" : client?.client;
    return referrer;
  };
  const customStyles = (error: any) => ({
    control: (base: any) => ({
      ...base,
      height: 35,
      minHeight: 35,
      borderColor: error ? "red" : "inherit",
    }),
  });
  const getBorrower = async (
    phoneNumber: string,
    xtag: string,
    bankCode: string,
    accountNumber: string
  ) => {
    const referrer = setReferrer(client);
    try {
      if (userData.length === 0) {
        const user = await registerCustomer(
          phoneNumber,
          xtag,
          referrer,
          bankCode,
          accountNumber
        );
        setUserData([...userData, user.data.data]);
        userContext.setBorrowers([user.data.data]);
      }
    } catch (error: any) {
      history.push(`/${clientname}/error`, {
        error: error?.response?.data?.message,
      });
    }
  };
  useEffect(() => {
    if (userContext.borrowers.length === 1) {
      if (originLender) {
        history.push(`/${clientname}/eligibility`);
      } else {
        history.push(`/${clientname}/consent`);
      }
    }
  }, [userData, userContext, history, clientname, originLender]);

  useEffect(() => {
    if (organizations.length) {
      setClientData({ ...client });
    }
  }, [organizations.length, client]);

  return (
    <div className="body-main m-auto">
      <div className="text-icon-container">
        <p className="font-weight-bolder my-auto mr-2 text-small text-left">
          Get the money you need in a short time!
        </p>
        <div className="loan-icon-container ml-auto">
          <img src={loanIcon} alt="loan-icon" className="loan-icon" />
        </div>
      </div>
      <Formik
        initialValues={{ phoneNumber: "", accountNumber: "", bankCode: "" }}
        onSubmit={async (values: Values) => {
          setSubmitting(true);
          await getBorrower(
            values.phoneNumber.replace(/ /g, ""),
            clientData["x-tag"],
            values.bankCode,
            values.accountNumber
          );
          setSubmitting(false);
        }}
        validationSchema={yup.object().shape({
          phoneNumber: yup
            .string()
            .required("Please provide your phone number")
            .matches(phoneRegExp, "Phone number is not valid")
            .min(9, "number too short")
            .max(15, "number too long"),
          accountNumber: yup
            .string()
            .required("Please provide account number")
            .length(10, "Account number not valid"),
          bankCode: yup.string().required("Please select your bank"),
        })}
      >
        {({ values, handleChange, handleBlur, errors, setFieldValue }) => (
          <Form>
            <InputField
              inputType="tel"
              name="phoneNumber"
              placeholder="Enter salary phone number"
              value={values.phoneNumber}
              onBlur={() => handleBlur}
              onChange={handleChange}
              error={errors.phoneNumber}
            />
            <InputField
              inputType="tel"
              name="accountNumber"
              placeholder="Enter salary account number"
              value={values.accountNumber}
              onBlur={() => handleBlur}
              onChange={handleChange}
              error={errors.accountNumber}
            />
            <Select
              options={
                (clientData &&
                  clientData.banklist.map(
                    (
                      details: { bankName: string; bankCode1: string },
                      index: number
                    ) => ({ label: details.bankName, value: details.bankCode1 })
                  )) ||
                []
              }
              onChange={(e: any) => setFieldValue("bankCode", e.value)}
              placeholder="Select bank"
              className="my-select"
              styles={customStyles(errors.bankCode)}
            />
            <Button
              type="submit"
              variant="primary"
              className="mt-3"
              isLoading={submitting}
            >
              Apply
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Apply;
