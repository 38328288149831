import React, { useContext } from "react";
import { Button } from "@chakra-ui/react";
import Icon from "@material-ui/core/Icon";
import "../App.css";
import { useLocation } from "react-router-dom";
import {
  borrowerContext,
  orgContext,
  originationContext,
} from "./../helpers/context";

const LoanFailed = ({ history }: any): JSX.Element => {
  const userContext = useContext(borrowerContext);
  const { organizations } = useContext(orgContext);
  const { borrowers, setBorrowers } = userContext;
  const { origin } = useContext(originationContext);
  const { originLender, originManualAmount, originThirdParty } = origin;
  const location = useLocation<{ error: string }>();
  const clientname = location.pathname.split("/")[1];
  const handleClick = () => {
    if (originLender) {
      window.location.href = `/create-loan/${clientname}`;
    } else if (originThirdParty) {
      window.location.href = `/thirdParty/${clientname}?x-tag=${organizations?.[0]?.["x-tag"]}&id=${borrowers?.[0]?.phoneNumber}`;
    } else if (originManualAmount) {
      window.location.href = `/v1/${clientname}`;
    } else if (location.state.error === "resource not found") {
      window.location.href = `/veendhqautolending`;
    } else {
      setBorrowers([]);
      history.push(`/${clientname}/apply`);
    }
  };
  return (
    <div className="body-main text-center m-auto">
      <Icon
        className="check-circle"
        fontSize="large"
        style={{ color: "orange" }}
      >
        warning
      </Icon>
      <p className="text-small mt-3">
        {location.state.error === "resource not found"
          ? "This lender does not exist"
          : location.state.error}
      </p>
      <Button variant="primary" className="mt-4" onClick={handleClick}>
        {location.state.error === "resource not found"
          ? "Take me to a lender"
          : "OK"}
      </Button>
    </div>
  );
};

export default LoanFailed;
