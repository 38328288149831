import axios from "axios";
import { handleError, handleResponse } from "./apiUtils";

export const baseUrl = process.env.REACT_APP_BASE_URL;

export const getLender = async (client: string) => {
  try {
    const response = await axios.get(
      `${baseUrl}/organization?client=${client}`
    );
    return handleResponse(response);
  } catch (error: any) {
    return handleError(error);
  }
};

export const registerCustomer = async (
  phoneNumber: string,
  xtag: string,
  referrer: string,
  bankCode?: string,
  accountNumber?: string
) => {
  let extraQueries = '';
  if(bankCode && accountNumber){
    extraQueries = `&bankCode=${bankCode}&accountNumber=${accountNumber}`
  }
  try {
    const response = await axios.post(
      `${baseUrl}/register?x-tag=${xtag}&phonenumber=${phoneNumber}&referrer=${referrer}` + extraQueries
    );
    return handleResponse(response);
  } catch (error: any) {
    return handleError(error);
  }
};

export const saveUserConsent = async (
  userId: string,
  xtag: string,
  token: string
) => {
  const config: any = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.post(
      `${baseUrl}/users/${userId}/consent?x-tag=${xtag}`,
      { consent: "yes" },
      config
    );
    return handleResponse(response);
  } catch (error: any) {
    return handleError(error);
  }
};

export const getLoanOptions = async (
  userId: string,
  xtag: string,
  token: string,
  offer: string,
  offerId: string
) => {
  const config: any = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const body: any = {
    offer: offer,
    _id: offerId,
  };
  try {
    const response = await axios.post(
      `${baseUrl}/users/${userId}/loanoptions?x-tag=${xtag}`,
      body,
      config
    );
    return handleResponse(response);
  } catch (error: any) {
    return handleError(error);
  }
};

export const calculateLoan = async (
  xtag: string,
  loanProduct: number,
  loanAmount: number | string,
  tenure: number,
  clientId: string,
  interestRate: number
) => {
  const body: any = {
    loanProduct,
    loanAmount,
    tenure,
    clientId,
    interestRate,
  };
  try {
    const response = await axios.post(
      `${baseUrl}/calculate?x-tag=${xtag}`,
      body
    );
    return handleResponse(response);
  } catch (error: any) {
    return handleError(error);
  }
};

export const acceptLoanTerms = async (
  loanAmount: string | number,
  xtag: string,
  userId: string,
  eligibilityId: string,
  token: string
) => {
  const config: any = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const body: any = {
    amount: loanAmount,
    _id: eligibilityId,
    channel: "WEB",
  };
  try {
    const response = await axios.post(
      `${baseUrl}/v1/users/${userId}/accept?x-tag=${xtag}`,
      body,
      config
    );
    return handleResponse(response);
  } catch (error: any) {
    return handleError(error);
  }
};

export const sendOTP = async (xtag: string, token: string) => {
  const config: any = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.post(
      `${baseUrl}/sendotp?x-tag=${xtag}`,
      {},
      config
    );
    return handleResponse(response);
  } catch (error: any) {
    return handleError(error);
  }
};

export const verifyOTP = async (
  xtag: string,
  token: string,
  otp: string,
  firstName: string,
  lastName: string,
  email: string
) => {
  const body: any = {
    firstName: firstName,
    lastName: lastName,
    email: email,
    otp: otp,
  };
  const config: any = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios.post(
      `${baseUrl}/verifyotp?x-tag=${xtag}`,
      body,
      config
    );
    return handleResponse(response);
  } catch (error: any) {
    return handleError(error);
  }
};

export const disburseLoan = async (
  xtag: string,
  userId: string,
  token: string,
  firstName: string,
  lastName: string,
  email: string
) => {
  const config: any = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const body: any = {
    firstname: firstName,
    lastname: lastName,
    email: email,
  };
  try {
    const response = await axios.post(
      `${baseUrl}/v1/users/${userId}/disburse?x-tag=${xtag}`,
      body,
      config
    );
    return handleResponse(response);
  } catch (error: any) {
    return handleError(error);
  }
};
