import { createContext } from "react";
import {
  borrowerContextType,
  orgContextType,
  confirmIdentityContextType,
  originationContextType,
} from "../types";

export const borrowerContext = createContext({} as borrowerContextType);
export const orgContext = createContext({} as orgContextType);
export const confirmIdentityContext = createContext(
  {} as confirmIdentityContextType
);
export const originationContext = createContext({} as originationContextType);
