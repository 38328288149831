import React from "react";
import { Route, Switch, useLocation } from "react-router-dom";

import Initialize from "../Initialize";

function Entry() {
  const location = useLocation();
  const clientname = location.pathname.split("/")[1];
  return (
    <Switch>
      <Route exact path="/" render={() => <div></div>} />
      <Route path={`/${clientname}`} component={Initialize} />
    </Switch>
  );
}

export default Entry;
