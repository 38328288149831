import React, { useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "../App.css";
import Icon from "@material-ui/core/Icon";
import { Button, Spinner } from "@chakra-ui/react";
import RadioButton from "../components/common/RadioButton";
import { saveUserConsent } from "../helpers/api";
import { borrowerContext, orgContext } from "../helpers/context";
import { IEligibilityOptions, ILoanOptions } from "../types";

interface EligibilityProps {
  history: any;
}

const Eligibility = ({ history }: EligibilityProps): JSX.Element => {
  const [selectedValue, setSelectedValue] = useState("");
  const [eligibilityOptions, setEligibilityOptions] = useState<
    IEligibilityOptions[]
  >([]);
  const [loanOptions, setLoanOptions] = useState<ILoanOptions[]>([]);
  const [eligibilityId, setEligibilityId] = useState("");

  const [loading, setLoading] = useState(false);
  const { borrowers } = useContext(borrowerContext);
  const { organizations } = useContext(orgContext);
  const [user] = borrowers;
  const [client] = organizations;

  const location = useLocation();
  const clientname = location.pathname.split("/")[1];

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (eligibilityOptions.length === 0) {
        try {
          const options = await saveUserConsent(
            user._id,
            client["x-tag"],
            user.token
          );
          setEligibilityOptions([...eligibilityOptions, options?.data?.data]);
        } catch (error: any) {
          history.push(`/${clientname}/error`, {
            error: error?.response?.data?.message,
          });
        }
      }
      setLoading(false);
    })();
  }, [client, clientname, eligibilityOptions, history, user]);

  useEffect(() => {
    if (eligibilityOptions.length > 0) {
      setLoanOptions(eligibilityOptions[0].loanOptions);
      setEligibilityId(eligibilityOptions[0]._id);
    }
  }, [eligibilityOptions]);

  return (
    <div className="body-main m-auto text-center">
      {loading ? (
        <Spinner size="xl" color="primaryBlue" className="my-5" />
      ) : null}
      <div className="steps-wrapper">
        <div className="step active">
          <span className="bold-text text-smaller">1</span>
        </div>
        <span className="step-divider my-auto"></span>
        <div className="step">
          <span className="bold-text text-smaller">2</span>
        </div>
        <span className="step-divider my-auto"></span>
        <div className="step">
          <span className="bold-text text-smaller">3</span>
        </div>
        <span className="step-divider my-auto"></span>
        <div className="step">
          <span className="bold-text text-smaller">4</span>
        </div>
      </div>
      <h5 className="bold-text text-small mb-4">Loan Application</h5>
      {loanOptions.length > 0 ? (
        <>
          <div className="eligibility-status">
            <Icon className="check-circle">check_circle</Icon>
            <p className="bold-text ml-3 my-auto text-small">
              You are Eligible for a Loan
            </p>
          </div>
          <div className="my-3">
            <p className="text-smaller font-weight-bolder">
              How long do you want it for?
            </p>
          </div>
          <div className="radio-button-group">
            {loanOptions?.map((option, index) => {
              return (
                <RadioButton
                  label={option.title}
                  name={option.title}
                  value={option.offer}
                  id={option.title}
                  selectedValue={selectedValue}
                  setSelectedValue={setSelectedValue}
                  key={index}
                />
              );
            })}
          </div>
        </>
      ) : (
        <div className="text-center">
          <Spinner size="md" color="primaryBlue" className="my-5 mx-auto" />
        </div>
      )}
      <Button
        type="submit"
        variant="primary"
        className="mt-4"
        onClick={() => {
          if (selectedValue === "") {
            alert("please select an option");
          } else {
            history.push(`/${clientname}/selectamount`, {
              offer: selectedValue,
              eligibilityId,
            });
          }
        }}
      >
        Submit
      </Button>
    </div>
  );
};

export default Eligibility;
