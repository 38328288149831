import React from "react";
import "./../styles/radioGroupStyles.css";

interface RadioButtonProps {
  label: string;
  name: string;
  id: string;
  value: string;
  selectedValue: string;
  setSelectedValue: (value: string) => void;
}

const RadioButton: React.FC<RadioButtonProps> = ({
  label,
  name,
  value,
  id,
  selectedValue,
  setSelectedValue,
}) => {
  return (
    <div className="RadioButton">
      <input
        id={id}
        name={name}
        value={value}
        type="radio"
        onChange={(e) => {
          setSelectedValue(e.currentTarget.value);
        }}
        checked={selectedValue === value}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};

export default RadioButton;
