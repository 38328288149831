import { whiten } from "@chakra-ui/theme-tools";

export const ButtonStyles = {
  // The styles all button have in common
  baseStyle: {
    fontWeight: "bold",
    textTransform: "capitalise",
    fontFamily: "Montserrat",
    borderRadius: "base", // <-- border radius is same for all variants and sizes
  },
  // styles for different sizes ("sm", "md", "lg")
  sizes: {
    sm: {
      fontSize: "14px",
      lineHeight: "0",
      px: "2rem", // <-- px is short for paddingLeft and paddingRight
      py: ".8rem", // <-- py is short for paddingTop and paddingBottom
    },
    md: {
      fontSize: "1rem",
      lineHeight: "0",
      px: "3rem",
      py: "1rem",
    },
  },
  // styles for different visual variants ("outline", "solid")
  variants: {
    primary: {
      bg: "primaryBlue",
      color: "white",
      _hover: {
        bg: whiten("primaryBlue", 20),
        transform: "scale(1.02)",
      },
    },
    secondary: {
      bg: "white",
      border: "1px solid",
      borderColor: "primaryBlue",
      color: "primaryBlue",
      _hover: {
        bg: whiten("primaryBlue", 40),
        transform: "scale(1.02)",
      },
    },
  },
  // default values for `size` and `variant`
  defaultProps: {
    size: "sm",
    variant: "primary",
  },
};
