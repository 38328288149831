import React, { useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { AppTheme } from "./theme";
import {
  borrowerContext,
  orgContext,
  confirmIdentityContext,
  originationContext,
} from "./helpers/context";
import { IBorrower, IIdentity, IOrg, IOriginationContext } from "./types";
import Entry from "./views/Entry";

function App() {
  const [organizations, setOrganizations] = useState<IOrg[]>([]);
  const [borrowers, setBorrowers] = useState<IBorrower[]>([]);
  const [identities, setIdentities] = useState<IIdentity[]>([]);
  const [origin, setOrigin] = useState<IOriginationContext>({
    originLender: false,
    originManualAmount: false,
    originThirdParty: false,
  });
  return (
    <ChakraProvider theme={AppTheme}>
      <orgContext.Provider value={{ organizations, setOrganizations }}>
        <borrowerContext.Provider value={{ borrowers, setBorrowers }}>
          <confirmIdentityContext.Provider
            value={{ identities, setIdentities }}
          >
            <originationContext.Provider value={{ origin, setOrigin }}>
              <Router>
                <Switch>
                  <Route path="/" component={Entry} />
                </Switch>
              </Router>
            </originationContext.Provider>
          </confirmIdentityContext.Provider>
        </borrowerContext.Provider>
      </orgContext.Provider>
    </ChakraProvider>
  );
}

export default App;
