import React, { useState, useContext, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import "../App.css";
import Icon from "@material-ui/core/Icon";
import { Button, Spinner } from "@chakra-ui/react";
import RadioButton from "../components/common/RadioButton";
import {
  borrowerContext,
  orgContext,
  originationContext,
} from "../helpers/context";
import { getLoanOptions } from "../helpers/api";
import { IAmountOptions } from "../types";
import { formatNumberAmount } from "../helpers/utilFunctions";
import AmountInputForm from "../components/AmountInputForm";

interface LoanAmountProps {
  history: any;
}

const LoanAmount = ({ history }: LoanAmountProps): JSX.Element => {
  const location = useLocation<{ offer: string; eligibilityId: string }>();
  const [selectedValue, setSelectedValue] = useState("");
  const [amountOptions, setAmountOptions] = useState<IAmountOptions[]>([]);
  const [amounts, setAmounts] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const { borrowers } = useContext(borrowerContext);
  const { organizations } = useContext(orgContext);
  const { origin } = useContext(originationContext);
  const [user] = borrowers;
  const [client] = organizations;
  const clientname = location.pathname.split("/")[1];
  const { originManualAmount } = origin;

  const populateLoanOptions = useCallback(async () => {
    if (amountOptions.length === 0) {
      try {
        const options = await getLoanOptions(
          user._id,
          client["x-tag"],
          user.token,
          location.state.offer,
          location.state.eligibilityId
        );
        setAmountOptions([...amountOptions, options.data.data]);
      } catch (error: any) {
        history.push(`/${clientname}/error`, {
          error: error?.response?.data?.message,
        });
      }
    }
  }, [client, clientname, amountOptions, history, user, location]);

  useEffect(() => {
    setLoading(true);
    populateLoanOptions();
    setLoading(false);
  }, [populateLoanOptions]);

  useEffect(() => {
    if (amountOptions.length > 0) {
      setAmounts(amountOptions[0].options);
    }
  }, [amountOptions]);

  const renderLoanAmounts = () => {
    return (
      <>
        <div className="radio-button-group">
          {amounts.length > 0 ? (
            amounts?.map((amount, index) => {
              return (
                <RadioButton
                  label={`\u20A6 ${amount}`}
                  name={amount}
                  value={amount}
                  id={amount}
                  selectedValue={selectedValue}
                  setSelectedValue={setSelectedValue}
                  key={index}
                />
              );
            })
          ) : (
            <Spinner size="md" color="primaryBlue" className="my-5" />
          )}
        </div>
        <Button
          type="submit"
          variant="primary"
          className="mt-4"
          onClick={() => {
            if (selectedValue === "") {
              alert("please select an option");
            } else {
              history.push(`/${clientname}/loanterms`, {
                loanAmount: selectedValue,
                eligibilityId: location.state.eligibilityId,
                tenure: amountOptions[0]?.tenure,
              });
            }
          }}
        >
          Proceed
        </Button>
      </>
    );
  };

  const handleProceed = (amount: string) => {
    history.push(`/${clientname}/loanterms`, {
      loanAmount: amount,
      eligibilityId: location.state.eligibilityId,
      tenure: amountOptions[0]?.tenure,
    });
  };

  return (
    <div className="body-main m-auto text-center">
      {loading ? (
        <Spinner size="xl" color="primaryBlue" className="my-5" />
      ) : null}
      <div className="steps-wrapper">
        <div className="step active">
          <span className="bold-text text-smaller">1</span>
        </div>
        <span className="step-divider active my-auto"></span>
        <div className="step active">
          <span className="bold-text text-smaller">2</span>
        </div>
        <span className="step-divider my-auto"></span>
        <div className="step">
          <span className="bold-text text-smaller">3</span>
        </div>
        <span className="step-divider my-auto"></span>
        <div className="step">
          <span className="bold-text text-smaller">4</span>
        </div>
      </div>
      <h5 className="bold-text text-small mb-4">Loan Application</h5>
      <div className="eligibility-status">
        <Icon className="check-circle">check_circle</Icon>
        <p className="bold-text ml-3 my-auto text-smaller">
          Maximum Eligible Amount:{" "}
        </p>
        {amounts.length > 0 ? (
          <p className="bold-text ml-1 my-auto text-smaller">
            {`\u20A6 ${formatNumberAmount(amountOptions[0]?.maxAmount)}`}
          </p>
        ) : (
          <Spinner size="sm" color="primaryBlue" className="ml-2" />
        )}
      </div>
      <p className="mt-4 text-small font-weight-bolder">
        How much do you want to borrow?
      </p>
      {originManualAmount ? (
        <>
          {amounts.length > 0 ? (
            <AmountInputForm
              maxAmount={amountOptions[0].maxAmount}
              handleProceed={handleProceed}
              minAmount={amountOptions[0].minAmount}
            />
          ) : (
            <Spinner size="md" color="primaryBlue" className="my-5" />
          )}
        </>
      ) : (
        renderLoanAmounts()
      )}
    </div>
  );
};

export default LoanAmount;
