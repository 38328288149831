export function formatStringAmount(amount: string): string {
  const formatter = amount
    ? amount.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    : "0";

  return formatter;
}

export function formatNumberAmount(amount: number): string {
  const formatter = amount
    ? amount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    : "0";

  return formatter;
}

export const capitalizeString = (string: string) => {
  return string && string[0].toUpperCase() + string.slice(1);
};

// not yet used
export function CalculateAmountDifference(
  amount: string,
  serviceFee: string
): number {
  const difference = parseInt(amount) - parseInt(serviceFee);

  return difference;
}
